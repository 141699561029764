<template>
	<v-card class="my-4" outlined elevation="5" rounded="xl">
		<v-card-title>
			<strong>{{ course.name }}</strong>
		</v-card-title>
		<v-card-text>
			<v-simple-table>
				<template v-slot:default>
					<thead>
						<tr>
							<th />
							<th v-for="(header, index) in course.data.estacion" :key="index">
								{{ header }}
							</th>
						</tr>
						<tr>
							<th />
							<th v-for="(subheader, index) in course.data.tipo_examen" :key="index">
								{{ subheader }}
							</th>
						</tr>
					</thead>

					<tbody>
						<tr v-for="(exams, year) in course.data.year" :key="year">
							<th>{{ year }}</th>
							<td v-for="(exam, indexExam) in exams" :key="indexExam">
								<v-icon v-if="exam == 'xx'" color="success"> mdi-check-all </v-icon>
								<v-icon v-else-if="exam == 'x'" color="blue"> mdi-check </v-icon>
								<v-icon v-else-if="exam == ''" color="error"> mdi-close </v-icon>
								<v-icon v-else-if="exam == 'no'" color="yellow lighten-3"> mdi-crosshairs-off </v-icon>
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'CourseExamsCard',
	props: {
		course: {
			type: Object,
			required: true,
		},
	},
}
</script>
